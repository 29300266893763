import { useEffect } from 'react';
import Amplify, { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

const awsconfig = {
  aws_project_region: process.env.NEXT_PUBLIC_AWS_REGION,
  aws_cognito_identity_pool_id:
    process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_AWS_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.NEXT_PUBLIC_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_region: process.env.NEXT_PUBLIC_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

Amplify.configure(awsconfig);

export default function SessionLoader({ setUser }) {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setUser)
      .catch(() => setUser(null));
  }, [setUser]);

  useEffect(() => {
    const authHandler = (data) => {
      if (data.payload.event === 'signIn') {
        setUser(data.payload.data);
      } else if (data.payload.event === 'signOut') {
        setUser(null);
      }
    };

    Hub.listen('auth', authHandler);
    return () => {
      Hub.remove('auth', authHandler);
    };
  }, [setUser]);

  return null;
}
